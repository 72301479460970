<template>
  <head-meta service="MyRenderer" />
  <v-app>
    <div class="full-screen">
      <Header />
      <Navigations />

      <Popup />

      <div class="main">
        <NuxtPage />
      </div>

      <DialogContainer
        :id="dialog.id"
        v-for="dialog in dialogStore.dialogs"
        :key="dialog.id"
      />
    </div>
  </v-app>
</template>

<script lang="ts" setup>
const dialogStore = useDialogStore()
</script>

<style lang="scss" scoped>
.full-screen {
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;

  > .main {
    overflow: hidden;
    order: 1;
    flex-grow: 1;
    height: 100%;
    margin: 0.25rem 0;
    background-color: rgb(var(--v-theme-background));
    @include media(sm) {
      margin: 0;
    }
  }
}
</style>
